<div *ngIf="panelId == 0">
<p>Rewiring of cellular division site selection in evolution of fission yeasts.</p>

<p>Originally published in <a href='https://doi.org/10.1016/j.cub.2015.02.056'>Curr Biol</a>.</p>

</div>
<div *ngIf="panelId == 1">
<p>Stress-activated MAPK signalling controls fission yeast actomyosin ring integrity by modulating formin For3 levels.</p>

<p>Originally published in <a href='https://elifesciences.org/articles/57951'>eLife</a>. Image from <a href="https://elifesciences.org/articles/57951#fig8">eLife 2020;9:e57951</a>, used under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY</a> license terms.</p>

</div>
<div *ngIf="panelId == 2">
<p>Mis16 Independently Recognizes Histone H4 and the CENP-ACnp1-Specific Chaperone Scm3sp.</p>

<p>Originally published in <a href='https://www.sciencedirect.com/science/article/abs/pii/S0022283615004878'>J Mol Biol</a>.</p>

</div>
<div *ngIf="panelId == 3">
<p>Yeast-to-hypha transition of Schizosaccharomyces japonicus in response to environmental stimuli.</p>

<p>Originally published in <a href='https://www.molbiolcell.org/doi/10.1091/mbc.E18-12-0774'>Mol Biol Cell</a>.</p>

</div>
<div *ngIf="panelId == 4">
<p>Environmentally controlled dimorphic cycle in a fission yeast.</p>

<p>Originally published in <a href='https://www.microbiologyresearch.org/content/journal/micro/10.1099/00221287-144-5-1319'>Microbiology (Reading)</a>.</p>

</div>
<div *ngIf="panelId == 5">
<p>Separable roles for RNAi in regulation of transposable elements and viability in the fission yeast Schizosaccharomyces japonicus.</p>

<p>Originally published in <a href='https://journals.plos.org/plosgenetics/article?id=10.1371/journal.pgen.1010100'>PLOS Genetics</a></p>

</div>
<div *ngIf="panelId == 6">
<p>Synchronous activation of cell division by light or temperature stimuli in the dimorphic yeast Schizosaccharomyces japonicus.</p>

<p>Originally published in <a href='https://journals.asm.org/doi/10.1128/EC.00109-13'>Eukaryotic Cell</a></p>

</div>
<div *ngIf="panelId == 7">
<p><app-recent-community-pubs></app-recent-community-pubs></p>

</div>
<div *ngIf="panelId == 8">
<p><app-recent-community-pubs></app-recent-community-pubs></p>

</div>
<div *ngIf="panelId == 9">
<p>Use the <a routerLink="/query">Advanced Search</a> to construct complex queries (GO, phenotypes, taxonomic distribution, domain, chromosomal location).</p>

</div>
<div *ngIf="panelId == 10">
<p>JaponicusDB provides extensive documentation and answers many questions in the <a routerLink="/faq">FAQ</a>. For any information you can’t find there, please <app-contact-email [linkName]="'contact the helpdesk'" [subject]="'JaponicusDB question'"></app-contact-email>.</p>

</div>
<div *ngIf="panelId == 11">
<p>PomBase has pioneered a community curation system that enables researchers to contribute publication-based annotations directly to database curators. To participate, search for the PubMed ID of your paper <a href="https://curation.pombase.org/pombe">in Canto</a> or <app-contact-email [linkName]="'contact the helpdesk'" [subject]="'PomBase question'"></app-contact-email>.</p>

</div>
